import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import { CheckValidateToken, doLogin, resetPwd, loginSelector } from "../../store/reducer/login";
import { API_STATUS } from "../../utils/constants";
import toast from 'react-hot-toast';
import SimpleReactValidator from 'simple-react-validator';
import LinkExpired from '../../images/link_expired.png';
var bnr = require('./../../images/banner/bnr3.jpg');
var img1 = require('./../../images/background/bg5.jpg');

const Resetpassword = ({ history }) => {

	let { id } = useParams();
	const formValidator = useRef(new SimpleReactValidator());
	let alreadyLogged = localStorage.getItem('isAuthenticated');
	console.log(alreadyLogged, 'alreadyLogged');
	let temp_token = localStorage.getItem('temporaryToken');
	var host = window.location.protocol + "//" + window.location.host;
	// window.scroll({ top: 0, left: 0, behavior: "smooth", });
	// if (alreadyLogged) {
	// 	// alert(window.history.back());
	// 	toast.success('User already logged in.!');
	// 	// window.location.href = host
	// 	window.setTimeout(function () {
	// 		window.location.href = host
	// 	}, 3000);
	// }

	const dispatch = useDispatch();

	const { TokenLoading, TokenDatas, loading, resetloading, errorMessage } = useSelector(loginSelector);

	const [loginDetails, setLoginDetails] = useState({ new_password: "", confirm_password: "", submitted: false, loading: false });
	const [PasswordType, setPasswordType] = useState('password');
	const [NewPasswordType, setNewPasswordType] = useState('password');
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [showError, setshowError] = useState('');


	const submitHandler = (e) => {
		e.preventDefault();
	};
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const CheckTokenDispatch = (id) => {
		dispatch(
			CheckValidateToken({ token: id })
		);
	};

	useEffect(() => {
		CheckTokenDispatch(id)
	}, [])

	const onLoginSubmit = (e) => {
		e.preventDefault();
		// alert('click')
		// return false
		setAddFormSubmit(true);
		let postData = loginDetails;
		postData.token = id
		if (formValidator.current.allValid()) {
			dispatch(resetPwd({ postData }));
		}


	};

	useEffect(() => {
		formValidator.current.showMessages()
	}, [])


	useEffect(() => {
		console.log(loading, "loading")
		if (loading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Loggedin Successfully!");

			window.setTimeout(function () {
				window.location.href = '/'
			}, 1000);

		}
		if (loading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (loading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(errorMessage);
		}
	}, [loading]);

	useEffect(() => {
		console.log(TokenLoading, "TokenLoading")
		if (TokenLoading === API_STATUS.FULFILLED) {
			toast.dismiss();

			// toast.success("Loggedin Successfully!");

			// window.setTimeout(function () {
			// 	window.location.href = '/'
			// }, 1000);

		}
		if (TokenLoading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (TokenLoading === API_STATUS.REJECTED) {
			toast.dismiss();
			//toast.error(errorMessage);
		}
	}, [TokenLoading]);

	useEffect(() => {

		if (resetloading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Password updated Successfully!");

			window.setTimeout(function () {
				window.location.href = '/login'
			}, 1000);

		}
		if (resetloading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (resetloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(errorMessage);
		}
	}, [resetloading]);

	const togglePassword = () => {
		if (PasswordType == 'text') {
			setPasswordType('password')
		} else {
			setPasswordType('text')

		}
	}

	const toggleNewPassword = () => {
		if (NewPasswordType == 'text') {
			setNewPasswordType('password')
		} else {
			setNewPasswordType('text')

		}
	}

	useEffect(() => {

		if (TokenDatas) {
			console.log(TokenDatas, 'TokenDatas');
		}
	}, [TokenDatas])

	console.log(TokenDatas, 'TokenDatas-out');

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				{/* <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Login</h1>

							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'} onClick={() => {
										window.scroll({ top: 0, left: 0, behavior: "smooth", });
									}}>Home</Link></li>
									<li>Login</li>
								</ul>
							</div>
						</div>
					</div>
				</div> */}
				<div className="section-full content-inner shop-account" style={{ backgroundImage: "url('" + img1 + "')", backgroundSize: "100%", paddingTop: '33px' }}>
					<div className="container">
						{
							(TokenDatas && TokenDatas.status == true) ?

								<><div className="row">
									<div className="col-lg-12 text-center">
										<h2 className="m-t0 m-b40 m-md-b20">Reset Your Password</h2>
									</div>
								</div>
									<div className="row align-content-stretch">
										<div className="col-lg-3 col-md-3 m-b30">

										</div>
										<div className="col-lg-6 col-md-6 m-b30">
											<div className="p-a30 border-1 radius-sm">
												<div className="tab-content tab-form nav1">
													<TabContent activeTab={activeTab}>
														<TabPane tabId="1">
															<form id="login" onSubmit={(e) => submitHandler(e)} className="tab-pane active col-12 p-a0">
																<div className="form-group">
																	<label>New Password <span style={{ color: 'red' }}>*</span></label>
																	<div className='position-relative'>
																		<input
																			className="form-control"
																			id="signin-password"
																			name='new_password'
																			placeholder="Password"
																			type={PasswordType}
																			value={loginDetails.new_password}
																			autoComplete="off"
																			onChange={val => {
																				setLoginDetails({ ...loginDetails, new_password: val.target.value });
																			}}
																		/> <span style={{ position: 'absolute', top: '11px ', right: '15px' }}>
																			{(PasswordType === 'password') ? <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
																				< i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
																		</span>
																	</div>
																	{formValidator.current.message(
																		'password',
																		loginDetails.new_password,
																		['required',
																			{
																				regex: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!/#%*?&])[A-Za-z\d@$!/#%*?&]{8,15}$/
																			}
																		],
																		{
																			className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																			messages: {
																				required: 'Please Enter Password',
																				password: 'Please enter valid password',
																				regex: 'Minimum 8 and Maximum 15 characters,at least include one Uppercase letter, one Lowercase letter and one Special character (@$!/#%*?&)',
																			}
																		}
																	)}
																</div>
																<div className="form-group">
																	<label>Confirm Password <span style={{ color: 'red' }}>*</span></label>
																	<div className='position-relative'>
																		<input
																			className="form-control"
																			id="signin-password"
																			name='confirm_password'
																			placeholder="Confirm Password"
																			type={NewPasswordType}
																			value={loginDetails.confirm_password}
																			autoComplete="off"
																			onChange={val => {
																				setLoginDetails({ ...loginDetails, confirm_password: val.target.value });
																			}}
																		/> <span style={{ position: 'absolute', top: '11px ', right: '15px' }}>
																			{(NewPasswordType === 'password') ? <i onClick={toggleNewPassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
																				< i onClick={toggleNewPassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
																		</span>
																	</div>
																	{formValidator.current.message(
																		'confirm_password',
																		loginDetails.confirm_password,
																		['required', `in:${loginDetails.new_password}`,
																		],
																		{
																			className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																			messages: {
																				required: 'Please Enter Confirm Password',
																				// regex: 'Please Enter Valid Password',
																				in: "New Password and Confirm password doesn't match"
																			}
																		}
																	)}
																</div>
																{/* <span style={{ color: 'red' }}>{ showError }</span> */}
																<div className="text-left">

																	<button
																		className="btn btn-primary btn-lg btn-block1"
																		href="javascript:void(0);" onClick={onLoginSubmit}
																	>Submit</button> &nbsp;&nbsp;

																</div>

															</form>
														</TabPane>
													</TabContent>
												</div>
											</div>
										</div>
									</div></>
								:
								<div className="col-lg-12 col-md-12" style={{ textAlign: 'center' }}>
									<h3>Link Expired</h3>
									<p>To reset your password, Click below button and select "Forgot Password" to send a new email. </p>
									<div className="form-group">
										<Link to={'/login'} className="btn btnhover" >Click here to login</Link>
									</div>
								</div>

						}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)

}

export default Resetpassword;