import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { validateRedeemCoupon, getSharedGiftcardDetails, getAllUsers, removeFavouriteProduct, getUserGiftcardDetails, addToProductFavourite, getProfileDetails, ProfileUpdateDetails, PasswordUpdateDetails, validateGiftCard, BuyGiftCardTable } from "../../services/api";
import { secretKey } from "../../services/config";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { toast } from "react-hot-toast";

const namespace = "profile";

const initialState = {
    errorMessage: null,
    profileDatas: null,
    profileloading: "initial",
    updateLoading: 'initial',
    profileerrorMessage: null,
    success: '',
    passwordloading: "initial",
    passwordMessage: null,
    addloading: "initial",
    removeLoading:"",
    addMessage: null,
    giftDatas: null,
    giftDataEmails: null,
    giftMessage: null,
    giftloading: "initial",
    userDatas: null,
    userMessage: null,
    userloading: "initial",
    redeemDatas: null,
    redeemMessage: null,
    redeemloading: "initial",
    buygiftcardDatas: null,
    buygitfcardMessage: "initial",
    buygiftcardloading: null
};

export const profileData = createAsyncThunk(
    `${namespace}/profile`,
    async ({ user_id = '' }, { rejectWithValue, dispatch }) => {
        try {

            const result = await getProfileDetails(user_id);
            console.log("get profile details : ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const giftcardData = createAsyncThunk(
    `${namespace}/giftcardData`,
    async ({ user_id = '' }, { rejectWithValue, dispatch }) => {
        try {

            const result = await getUserGiftcardDetails(user_id);
            console.log("get profile details : ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const sharedGiftcardData = createAsyncThunk(
    `${namespace}/sharedGiftcardData`,
    async ({ user_id = '' }, { rejectWithValue, dispatch }) => {
        try {

            const result = await getSharedGiftcardDetails(user_id);
            console.log("get profile details : ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const profileUpdateData = createAsyncThunk(
    `${namespace}/profileUpdate`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.apiencryptdata(postData, secretKey)
            console.log(payload, 'decryptpayload==');
            const result = await ProfileUpdateDetails({ payload: payload });
            console.log("profiledata redu--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updatePassword = createAsyncThunk(
    `${namespace}/passwordUpdate`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.apiencryptdata(postData, secretKey)

            const result = await PasswordUpdateDetails({ payload: payload });
            console.log("PasswordUpdateDetails redu--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

// export const addToFavourite = createAsyncThunk(
//     `${namespace}/addToFavourite`,
//     async ({ postData}, { rejectWithValue, dispatch }) => {
//         try {

//             const result = await addToProductFavourite({ payload: postData });
//             console.log("addToProductFavourite redux--> ", result);
//             return result;
//         } catch (error) {
//             console.log("getScanCount error--->", error);
//             return rejectWithValue(error.response);
//         }
//     }
// );

export const addToFavourite = createAsyncThunk(
    `${namespace}/addtofavorites`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {

            const result = await addToProductFavourite({ payload: postData });
            console.log("addToProductFavourite redux--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const removeFavourite = createAsyncThunk(
    `${namespace}/removeFavourite`,
    async ({ postData1 }, { rejectWithValue, dispatch }) => {
        try {
            let postData = postData1
            console.log(postData, 'postData');
            const result = await removeFavouriteProduct({ payload: postData });

            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const userData = createAsyncThunk(
    `${namespace}/userData`,
    async ({ user_id = '' }, { rejectWithValue, dispatch }) => {
        try {
            const result = await getAllUsers();
            console.log("user redux--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const RedeemCouponData = createAsyncThunk(
    `${namespace}/RedeemCouponData`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            const result = await validateRedeemCoupon({ payload: postData });
            console.log("RedeemCouponData redux--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const BuygiftcardData = createAsyncThunk(
    `${namespace}/BuygiftcardData`,
    async ({ user_id = '' }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await BuyGiftCardTable({ user_id });
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const profileSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        // [profileData.pending](state, {payload}) {
        //     state.profileloading = API_STATUS.PENDING;
        //     // state.profileDatas = payload?.datas;
        // },
        [profileData.fulfilled](state, { payload }) {
            state.profileloading = API_STATUS.FULFILLED;

            console.log(payload, 'payload fullfilled');
            state.profileDatas = payload?.data.datas;
        },
        [profileData.rejected](state, action, { payload }) {
            state.profileloading = API_STATUS.REJECTED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.datas, secretKey));
            console.log(payload, 'payload erro');
            state.errorMessage = action?.payload?.datas;
        },
        [profileUpdateData.pending](state) {
            console.log('pending update');

            state.updateLoading = API_STATUS.PENDING;
        },
        [profileUpdateData.fulfilled](state, { payload }) {
            state.updateLoading = API_STATUS.FULFILLED;
            state.success = payload?.message;
            state.profileerrorMessage = payload?.message;

            // console.log(payload, 'update fullfilled');
            // state.profileDatas = payload?.message;
        },
        [profileUpdateData.rejected](state, action) {
            state.updateLoading = API_STATUS.REJECTED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.datas, secretKey));
            // console.log( action?.payload?.data?.errors[0].message, 'update rejected');
            state.profileerrorMessage = action?.payload?.data.message;
        },
        [updatePassword.pending](state) {
            console.log('pending update');

            state.passwordloading = API_STATUS.PENDING;
        },
        [updatePassword.fulfilled](state, { payload }) {
            state.passwordloading = API_STATUS.FULFILLED;
            console.log('pending update');

            state.passwordMessage = payload?.data;
        },
        [updatePassword.rejected](state, action) {
            state.passwordloading = API_STATUS.REJECTED;
            state.passwordMessage = action?.payload?.data?.errors[0].message;
        },
        [addToFavourite.pending](state) {
            console.log('pending update');

            state.addloading = API_STATUS.PENDING;
        },
        [addToFavourite.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
            toast.success("Product Added to Favourites Successfully !")
            console.log('fulfilled update');

            state.addMessage = payload?.message;
        },
        [removeFavourite.pending](state) {
            console.log('pending update');
            state.removeLoading = API_STATUS.PENDING;

        },
        [removeFavourite.fulfilled](state, { payload }) {
            toast.success("Product Removed from Favourites Successfully !")
            console.log('fulfilled update');
            state.removeLoading = API_STATUS.FULFILLED;

            state.addMessage = payload?.message;
        },
        [giftcardData.fulfilled](state, { payload }) {
            state.giftloading = API_STATUS.FULFILLED;
            console.log('pending update');

            state.giftDatas = payload?.data;
            state.giftDataEmails = payload?.userEmails;
        },
        [giftcardData.rejected](state, action) {
            state.giftloading = API_STATUS.REJECTED;
            state.giftMessage = action?.payload?.data?.errors[0].message;
        },
        [userData.pending](state) {
            console.log('pending update');

            state.userloading = API_STATUS.PENDING;
        },
        [userData.fulfilled](state, { payload }) {
            state.userloading = API_STATUS.FULFILLED;
            console.log('fulfilled update');

            state.userDatas = payload?.data;
        },
        [userData.rejected](state, action) {
            state.userloading = API_STATUS.REJECTED;
            state.userMessage = "Something went wrong. Please try again later.!";
        },
        [RedeemCouponData.pending](state) {
            console.log('pending update');

            state.redeemloading = API_STATUS.PENDING;
        },
        [RedeemCouponData.fulfilled](state, { payload }) {
            state.redeemloading = API_STATUS.FULFILLED;
            console.log('fulfilled update');

            state.redeemDatas = payload?.data;
        },
        [RedeemCouponData.rejected](state, action) {
            state.redeemloading = API_STATUS.REJECTED;
            state.redeemMessage = "Something went wrong. Please try again later.!";
        },
        [BuygiftcardData.pending](state) {
            console.log('pending update');

            state.buygiftcardloading = API_STATUS.PENDING;
        },
        [BuygiftcardData.fulfilled](state, { payload }) {
            state.buygiftcardloading = API_STATUS.FULFILLED;
            console.log('fulfilled update');

            state.buygiftcardDatas = payload?.data;
        },
        [BuygiftcardData.rejected](state, action) {
            state.buygiftcardloading = API_STATUS.REJECTED;
            state.buygitfcardMessage = "Something went wrong. Please try again later.!";
        },
    },
});

export const { clearData } = profileSlice.actions;

export const profileSelector = (state) => state.profile;

export default profileSlice.reducer;
