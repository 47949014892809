import React from 'react';

const VerticalProgressBar = ({ percentage,totalItemWeight,selectedItemWeight,name,weight }) => {
    const getBarColor = () => {
        if (percentage < 25) {
            return 'linear-gradient(to top, #b20000, #e50000)'; // Red gradient for <25%
        } else if (percentage < 75) {
            return 'linear-gradient(to top, #cccc00, #ffff00)'; // Yellow gradient for <75%
        } else {
            return 'linear-gradient(to top, #009900, #00e500)'; // Green gradient for >=75%
        }
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    const barStyle = {
        position: 'relative',
        width: '50px',
        height: weight == true ? '220px' : '120px',
        borderRadius: '4px',
        overflow: 'hidden',
        marginRight: '10px',
        border: '1px solid #ddd', // Add border style
        // marginLeft:'34px'
    };

    const progressBarStyle = {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        background: getBarColor(), // Background color for the unfilled portion
        height: '100%', // Fixed height of the progress bar
        transition: 'height 0.5s ease-in-out',
        clipPath: `inset(${100 - percentage}% 0 0 0)`,
        animation: 'pulse 2s infinite',
        border: '1px solid #ddd', // Border for the progress bar
    };

    const percentageTextStyle = {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        fontWeight: 'bold',
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#000', // Set your desired color
        opacity: 0, // Initially set opacity to 0
        transition: 'opacity 0.5s ease-in-out', // Add a transition for opacity
        fontSize:'17px'
      };
      
      // Add a class to trigger the animation
      if (selectedItemWeight > 0) {
        percentageTextStyle.opacity = 1;
      }

    return (
        <div style={containerStyle}>
            <span>{totalItemWeight}</span>
            <div style={barStyle}>
                <div style={progressBarStyle}></div>
                <div style={percentageTextStyle}>{selectedItemWeight}</div>
            </div>
            <span className='mb-2'>{name}</span>
        </div>
    );
};

export default VerticalProgressBar;
