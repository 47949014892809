import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
    websiteRecentProduct, websiteUpdateQuantityToCart, websiteProductList, websiteProductView, websiteAddToCart,
    websiteUpdateToCart, websiteCartList, websiteRemoveCart, websiteViewCart, createOrder
} from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "product";

const initialState = {
    loading: "initial",
    errorMessage: null,
    cartMessage: null,
    productDatas: null,
    productAddedToCart: 0,
    cartLoading: 'initial',
    getCartLoading: 'initial',
    cartDatas: null,
    quantityUpdated: true,
    getCartMessage: null,
    removeLoading: 'initial',
    removeMessage: null,
    viewLoading: "initial",
    viewcartDatas: null,
    viewMessage: null,
    allProductLoading: "initial",
    allProductDatas: null,
    allProductMessage: null,
    recentProductLoading: "initial",
    recentProductDatas: null,
    recentProductMessage: null,
    merchDatas:null

};

export const productData = createAsyncThunk(
    `${namespace}/product`,
    async ({ productid = 0 }, { rejectWithValue, dispatch }) => {
        try {
            console.log("productid--> ", productid);

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteProductView(productid);
            console.log("product--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const recentProductsData = createAsyncThunk(
    `${namespace}/recentProducts`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log('2');

            const data = await websiteRecentProduct(postData);
            console.log("recentproduct--> ", data);

            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const addToCart = createAsyncThunk(
    `${namespace}/addtocart`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {

            console.log(postData, 'reducer postData');


            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteAddToCart({ payload: postData });
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updateToCart = createAsyncThunk(
    `${namespace}/updatetocart`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {

            console.log(postData, 'reducer postData');


            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteUpdateToCart({ payload: postData });
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



export const updateQuantityData = createAsyncThunk(
    `${namespace}/updateQuantityData`,
    async ({ id = 0, quantity = 0 }, { rejectWithValue, dispatch }) => {
        try {

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteUpdateQuantityToCart({ payload: { id: id, quantity: quantity } });
            // let token = localStorage.getItem('temporaryToken')
            dispatch(
                cartData({})
            );
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const cartData = createAsyncThunk(
    `${namespace}/cartdata`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteCartList({ payload: { temp_token: localStorage.getItem('temporaryToken') } });
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const RemoveFromCart = createAsyncThunk(
    `${namespace}/removefromcart`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteRemoveCart(postData);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



export const viewCartData = createAsyncThunk(
    `${namespace}/viewcartdata`,
    async ({ cart_id = 0 }, { rejectWithValue, dispatch }) => {
        try {

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteViewCart({ payload: { id: cart_id } });
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const allProductsData = createAsyncThunk(
    `${namespace}/allProductsData`,
    async ({ query = "", page = 0, limit = 0, sortby = "", order = "", reward = 0 }, { rejectWithValue, dispatch }) => {
        try {
            console.log('ok okok');

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteProductList(query, page, limit, sortby, order, reward);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const orderCreate = createAsyncThunk(
    `${namespace}/orderCreate`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log(payload);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await createOrder(payload);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
const ProductSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [productData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            state.productAddedToCart = payload?.cartExists;
            state.productDatas = payload?.data;
            if(payload?.data?.data[0]?.category_id === 5){
            state.merchDatas = payload?.data?.data[0]?.merchimages.length > 0 ? payload?.data?.data[0]?.merchimages[0] : null
            }
        },
        [recentProductsData.pending](state, { payload }) {
            state.recentProductLoading = API_STATUS.PENDING;

        },
        [recentProductsData.fulfilled](state, { payload }) {
            state.recentProductLoading = API_STATUS.FULFILLED;
            console.log(payload?.data, 'payload recent');
            console.log(payload?.data?.data, 'payload recent data');
            // state.productAddedToCart = payload?.cartExists;
            state.recentProductDatas = payload?.data;
        },

        [addToCart.pending](state) {
            console.log('pending update');

            state.cartLoading = API_STATUS.PENDING;
        },
        [addToCart.fulfilled](state) {
            state.cartLoading = API_STATUS.FULFILLED;
            console.log('cart update');

            // state.cartMessage = payload?.data;
            // state.cartMessage = action?.payload?.data?.message;
        },
        [addToCart.rejected](state, action) {
            state.cartLoading = API_STATUS.REJECTED;
            console.log('cart reject');

            state.cartMessage = action?.payload?.data?.errors[0].message;
        },
        [cartData.pending](state) {
            console.log('cart get pending');
            state.quantityUpdated = true
            state.getCartLoading = API_STATUS.PENDING;
        },
        [cartData.fulfilled](state, { payload }) {
            state.getCartLoading = API_STATUS.FULFILLED;
            console.log('cart get---');

            state.cartDatas = payload?.data;
            state.quantityUpdated = false
        },
        [cartData.rejected](state, action) {
            state.getCartLoading = API_STATUS.REJECTED;
            // console.log(action?.payload.data, 'action payload');
            // console.log(action?.payload.data?.message, 'action payload');
            if (action?.payload?.data?.auth === "deleted") {
                if (action?.payload?.data?.message == 'UnAuthorized!') {
                    //toast.error(action?.payload?.data?.message);
                    let tempArray = localStorage.getItem('temporaryToken')
                    localStorage.clear();
                    localStorage.setItem('temporaryToken', tempArray)
                    window.setTimeout(function () {
                        window.location.href = '/'
                    }, 1000);


                } else {
                    window.location.href = "/maintenance"
                }
            }
            state.getCartMessage = action?.payload?.data?.message;
        },
        [RemoveFromCart.fulfilled](state, action) {
            state.removeLoading = API_STATUS.FULFILLED;
            state.removeMessage = action?.payload?.data?.message;

        },
        [RemoveFromCart.rejected](state, action) {
            state.removeLoading = API_STATUS.REJECTED;
            // console.log('get cart reject');

            state.removeMessage = action?.payload?.data?.errors[0].message;
        },
        [viewCartData.fulfilled](state, { payload }) {
            state.viewLoading = API_STATUS.FULFILLED;
            state.viewcartDatas = payload?.data;
        },
        [viewCartData.rejected](state, action) {
            state.viewLoading = API_STATUS.REJECTED;
            // console.log('get cart reject');

            state.viewMessage = action?.payload?.data?.errors[0].message;
        },

        [allProductsData.fulfilled](state, { payload }) {
            console.log(payload,'payload');
            state.allProductLoading = API_STATUS.FULFILLED;
            state.allProductDatas = payload?.data;
        },
        [allProductsData.rejected](state, action) {
            state.allProductLoading = API_STATUS.REJECTED;
            console.log('product list reject');
            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)

            state.viewMessage = action?.payload?.data?.errors[0].message;
        }
    },
});

export const { clearData } = ProductSlice.actions;

export const ProductSelector = (state) => state.product;

export default ProductSlice.reducer;
