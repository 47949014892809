import React, { useEffect } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Link, useParams } from 'react-router-dom';
// import { categoryData, categorySelector } from '../../store/reducer/category';
import { subcategoryData, subcategorySelector } from '../../store/reducer/subcategory';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from "../../services/config";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { Skeleton } from 'antd';

//Images	
var img1 = require('./../../images/background/bg5.jpg');



function Subcategory() {

	const detectDeviceType = () =>
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
			? 'Mobile'
			: 'Desktop';

	if (detectDeviceType() == 'Mobile') {
		window.location.href = 'mobilepage'
	}

	const { subcategoryDatas,loading } = useSelector(subcategorySelector);

	console.log(loading, 'loading');

	let { id } = useParams();

	id = EncryptDecrypt.decryptdata(id, secretKey);
	const dispatch = useDispatch();
	const subcategoryDispatch = (id) => {

		dispatch(
			subcategoryData({
				// query: searchQuery,
				// page: page + 1,
				// limit: rowsPerPage,
				// sortby: sortBy,
				// order: order,
				categoryid: id
			})
		);
	};

	useEffect(() => {
		subcategoryDispatch(id)
	}, [])

	console.log(subcategoryDatas, 'subcategoryDatas');

	// render(){
	return (
		<>
			<Header />

			<div className="page-content bg-white">
				<div className="content-block">
					{/* <Slider /> */}
					{
						(subcategoryDatas && subcategoryDatas.data) ?
							<div className="breadcrumb-row" style={{ backgroundColor: '#c1272d', paddingLeft: '20px' }}>
								<ul className="list-inline">
									<li><Link to={'/'} onClick={() => {
										window.scroll({ top: 0, left: 0, behavior: "smooth", });
									}} style={{ color: 'white' }}>Home</Link></li>
									{/* <li>{ subcategoryDatas.data ? subcategoryDatas.data.name : '-' }</li> */}

									<li>{(subcategoryDatas && subcategoryDatas.data) ? <Link to={'/'} style={{ color: 'white' }}>{subcategoryDatas.data.name}</Link> : ''}</li>

								</ul>
							</div>

							:
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'/'} onClick={() => {
										window.scroll({ top: 0, left: 0, behavior: "smooth", });
									}} style={{ color: 'white' }}>Home</Link></li>
									<li>{subcategoryDatas.data ? subcategoryDatas.data.name : '-'}</li>
								</ul>
							</div>}

					<div className="section-full content-inner-3" style={{ backgroundImage: "url(" + img1 + ")", top: '15px', marginLeft: '10px' }}>
						<div className="container">
						
							<div className="row service-area1" style={{ display: 'flex', justifyContent: 'center' }}>
								{loading != 'Fulfilled' ?
									<>
										<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 " style={{ maxWidth: '25rem' }}>
											<Skeleton.Image active style={{ height: '280px', width: '345px', borderRadius: '20px' }} />
											<center><Skeleton.Input active style={{ width: '225px', marginTop: '15px' }} size={'large'} />
												<Skeleton active paragraph={{ rows: 0 }} style={{ marginTop: '5px' }} /></center>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 " style={{ maxWidth: '25rem' }}>
											<Skeleton.Image active style={{ height: '280px', width: '345px', borderRadius: '20px' }} />
											<center><Skeleton.Input active style={{ width: '225px', marginTop: '15px' }} size={'large'} />
												<Skeleton active paragraph={{ rows: 0 }} style={{ marginTop: '5px' }} /></center>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 " style={{ maxWidth: '25rem' }}>
											<Skeleton.Image active style={{ height: '280px', width: '345px', borderRadius: '20px' }} />
											<center><Skeleton.Input active style={{ width: '225px', marginTop: '15px' }} size={'large'} />
												<Skeleton active paragraph={{ rows: 0 }} style={{ marginTop: '5px' }} /></center>
										</div>
									</> :
									(subcategoryDatas && subcategoryDatas.subcategories) ? subcategoryDatas.subcategories.map((result, index) => {

										// 									return  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 " style={{ maxWidth:'25rem' }}>
										// 										<a href={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)}>
										// 									<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url('" + assestURL + result.image + "')"}}>
										// 										<div className="icon-content" style={{ overflowWrap: 'break-word' }}>
										// 											<h2 className="dlab-tilte text-white">{ result.name } </h2>
										// 											<p>{ result.description }</p>
										// 											<div className="dlab-separator style1 bg-primary"></div>
										// 											{/* <Link to={(`/product-details`)} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link> */}
										// 											<Link to={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)} className="btn btnhover" onClick={() => {
										// window.scroll({ top: 0, left: 0, behavior: "smooth", }); }}>More details <i className="fa fa-angle-double-right m-l5"></i></Link>
										// 										</div>
										// 									</div></a>
										// 								</div>
										return <>

											<div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 " style={{ maxWidth: '25rem' }}>

												<a href={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)}>
													<div className="icon-bx-wraper text-center service-box2">
														<img src={`${assestURL + result.image}`} style={{ width: '345px', height: '280px', borderRadius: '10px', boxShadow: '2px solid grey' }} />
														<div className="icon-content" style={{ overflowWrap: 'break-word' }}>
															{/* <h2 className="dlab-tilte text-white">{result.name} </h2>
													<p>{result.description}</p> */}
															{/* <div className="dlab-separator style1 bg-primary"></div> */}
															{/* <Link to={(`/product-details`)} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link> */}
															{/* <Link to={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)} className="btn btnhover" onClick={() => {
														window.scroll({ top: 0, left: 0, behavior: "smooth", });
													}}>More details <i className="fa fa-angle-double-right m-l5"></i></Link> */}
														</div>
													</div></a>
												<div style={{ wordWrap: 'break-word', textAlign: 'center' }}>
													<h2 className="dlab-tilte text-black">{result.name} </h2>
													<p>{result.description}</p>
												</div>
											</div>
										</>
										// 	return<div className={'dz-col col m-b30'}><a href={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)}><div >  
										//     <div className="item-box shop-item style2">
										//         <div className="item-img">
										//             {
										//                <img src={ assestURL + result.image } alt="" />
										//             }
										//         </div>
										//         <div className="item-info text-center" style={{minHeight:'120px'}}>
										//         <h4 className="item-title" style={(result.name.length > 15) ? {fontSize:'21px', overflowWrap:'anywhere'} : {fontSize:'24px',overflowWrap:'anywhere'} }>{ result.name }</h4>
										// 				<h5 className="price text-primary"><span>sssdata</span></h5>
										//             <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> More Details</a></div>
										//         </div>
										//     </div>

										// </div>
										// </a>
										// </div>
									}) : <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-center-block">No Image </div>
								}
							</div>

						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
	// }
}

export default Subcategory;

