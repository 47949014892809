import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Input, Space, Skeleton } from 'antd';
import { allProductsData, ProductSelector } from '../../store/reducer/product';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from '../../services/config';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { getPosData, subcategoryProductSelector } from '../../store/reducer/subcategoryproducts';
import moment from 'moment'
import { generalData, generalSelector } from "../../store/reducer/general";
import 'moment-timezone';
import { toast } from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

var bnr = require('./../../images/banner/bnr1.jpg');

function RewardProductList() {

	window.scrollTo(0, 0)
	const dispatch = useDispatch();
	const { Search } = Input;
	const [fromTime, setFromTime] = useState(0);
	const [toTime, setToTime] = useState(0);
	const { allProductDatas, quantityUpdated } = useSelector(ProductSelector);
	const { posDatas } = useSelector(subcategoryProductSelector);
	const { generalDatas } = useSelector(generalSelector);
	const [timezone, setTimezone] = useState('America/Chicago');


	let alreadyLogged = localStorage.getItem('isAuthenticated');
	if (!alreadyLogged) {
		// alert(window.history.back());
		toast.dismiss();
		toast.success('User not authenticated.!');
		// window.location.href = host
		window.setTimeout(function () {
			window.location.href = '/'
		}, 550);
	}

	const productListDispatch = () => {

		dispatch(
			allProductsData({
				query: "", page: 0, limit: 0, sortby: "", order: "", reward: 1
			})
		);
	};
	const PosDispatch = () => {
		dispatch(
			getPosData({})
		);
	};

	const onSearch = (searchQuery) => {
		dispatch(
			allProductsData({
				query: searchQuery,
				page: 0, limit: 0, sortby: "", order: "", reward: 1
			})
		);
	}

	useEffect(() => {
		productListDispatch()
		PosDispatch()
	}, [])

	useEffect(() => {
		if (allProductDatas) {
			console.log(allProductDatas, 'allProductDatas');
		}
	}, allProductDatas);

	useEffect(() => {
		if (generalDatas && generalDatas.length) {
			setTimezone(generalDatas[0].timezone)
		}

		if (posDatas) {

			let endtime = '';
			let starttime = ''
			let showfromtime = 0;
			let showtotime = 0

			if (posDatas.data[0].start_time !== '' && posDatas.data[0].end_time !== '' && posDatas.data[0].schedule == 1) {
				let from_array = posDatas.data[0].start_time.split(' ') // AM,PM
				let to_array = posDatas.data[0].end_time.split(' ')

				starttime = from_array[0].split(':') //01, 22
				endtime = to_array[0].split(':')

				let frommoment = moment().tz(timezone).format('LT').split(' ')

				let restricttime = frommoment[0].split(':')
				let currenttime = restricttime[0];

				if (restricttime[0].length == 1) {
					currenttime = '0' + restricttime[0];
				}

				var timenow = parseInt(currenttime);
				var startnow = parseInt(starttime[0])
				var endnow = parseInt(endtime[0])

				if (from_array[1] === to_array[1]) {
					if (frommoment[1] !== from_array[1]) {
						showfromtime = 0;
						showtotime = 0;
					} else {

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1;
							// showtotime = 1;
						}

						if (startnow === timenow && (parseInt(starttime[1]) > parseInt(restricttime[1]))) {
							showfromtime = 0;
						}

						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1;
							// showfromtime = 1;

						}

						if (endnow === timenow && (parseInt(endtime[1]) < parseInt(restricttime[1]))) {
							showtotime = 0;
						}

						if (startnow !== timenow) {

							if ((startnow !== 12 && startnow < timenow)) {
								showfromtime = 1;
							}

							if (startnow === 12 && startnow > timenow) {
								showfromtime = 1;
							}
						}
						if (endnow !== timenow) {


							if (timenow !== 12 && (endnow > timenow)) {
								showtotime = 1;
							}

							if (timenow === 12 && (endnow < timenow)) {
								showtotime = 1;
							}
						}

					}
				}

				if (from_array[1] === 'AM' && to_array[1] === 'PM') {
					showfromtime = 0;
					showtotime = 0;

					if (frommoment[1] === 'AM') {
						showtotime = 1;
						if (parseInt(starttime[0]) === 12 && parseInt(restricttime[0]) === 12) {

							if (parseInt(starttime[1]) <= parseInt(restricttime[1])) {
								showfromtime = 1
							} else {
								showfromtime = 0
							}
						} else if (parseInt(starttime[0]) === 12) {
							showfromtime = 1
						} else {
							if (parseInt(starttime[0]) <= parseInt(restricttime[0]) && parseInt(restricttime[0]) != 12) {
								showfromtime = 1
								if (parseInt(starttime[0]) === parseInt(restricttime[0]) && parseInt(starttime[1]) <= parseInt(restricttime[1])) {
									showfromtime = 1
								}
							}
						}
					}

					if (frommoment[1] === 'PM') {
						showfromtime = 1;
						if (parseInt(endtime[0]) === 12 && parseInt(restricttime[0]) === 12) {

							if (parseInt(endtime[1]) >= parseInt(restricttime[1])) {
								showtotime = 1
							} else {
								showtotime = 0
							}
						} else if (parseInt(restricttime[0]) === 12) {
							showtotime = 1
						} else {
							if (parseInt(endtime[0]) >= parseInt(restricttime[0]) || parseInt(restricttime[0]) === 12) {
								showtotime = 1
								if (parseInt(endtime[0]) === parseInt(restricttime[0]) && parseInt(endtime[1]) >= parseInt(restricttime[1])) {
									showtotime = 1
								}
							}
						}
					}

				}

				if (from_array[1] === 'PM' && to_array[1] === 'AM') {

					if (frommoment[1] === 'PM' && startnow <= timenow) {
						showfromtime = 1
						showtotime = 1
						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1
						}

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 0
						}
					}

					if (frommoment[1] === 'AM' && endnow >= timenow) {
						showtotime = 1
						showfromtime = 1
						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1
						}

						if (endnow === timenow && (parseInt(endtime[1]) <= parseInt(restricttime[1]))) {
							showtotime = 0
						}
					}
				}

			}

			setFromTime(showfromtime)
			setToTime(showtotime)
		}

	}, [posDatas, generalDatas])
	console.log(posDatas, 'posDatas');
	console.log(allProductDatas,'allProductDatas.data');

	return (
		<>
			<Header />
			<div className="page-content bg-white">

				{/* <div className="dlab-bnr-inr overlay-black-middle" style={{backgroundImage:"url(" + bnr + ")"}}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Shop</h1>
							
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
									<li>Shop</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div> */}




				<div className="content-block">

					<div className="section-full content-inner1 bg-gray-light">
						<div className="col-lg-12">

							{(posDatas && posDatas.data) ? ((posDatas && posDatas.data[0].schedule == 0 || !fromTime || !toTime) ?

								<div className="tab-content product_list">
									<div id="web-design-1" className="tab-pane active">
										<p className="m-b0" style={{ color: 'red' }}> {posDatas.data[0].message} </p>
									</div>
								</div>
								: '') : ''
							}
						</div>
						<div className="container">
							<div className='row'>
								<div className='col-6'>
									<h3>Reward Products</h3>
									{
										(allProductDatas && allProductDatas.count) ? <p>Showing {allProductDatas.count} products</p> : ''
									}
								</div>
								<div className='col-6'>
									<Search className='mt-4 product-search' placeholder="Search by product name" size="large" onSearch={onSearch} enterButton />
								</div>
							</div>


							{
								(allProductDatas && allProductDatas.count == 0) ? <div className="col-lg-6 col-md-6" style={{ marginTop: '30px', marginLeft: '500px' }}>
									<h3>No Products Found</h3>
									{/* <div className="form-group">
									<Link to={'/'} className="btn btnhover" >Browse Products</Link>
								</div> */}
								</div> : ''
							}
							{quantityUpdated ?
								<>
									<div className='col-md-12 mt-4'>
										<div className='row'>
											<div className='ml-1'>
												<div className={'dz-col col m-b30'}><a><div >
													<div className="item-box shop-item style2">
														<div className="item-img">
															<Skeleton.Image active style={{ height: '300px', width: '210px' }} />
														</div>
														<div className="item-info text-center" style={{ minHeight: '120px' }}>
															<h4 className="item-title"><Skeleton.Input active /></h4>
															{/* <h5 className="price text-primary"><span>${parseFloat(allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size) && allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span></h5> */}
															<h5 className="price text-primary"><span><Skeleton.Input active /></span></h5>
															{/* <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div> */}
														</div>
													</div>

												</div>
												</a>
												</div>
											</div>
											<div className=''>
												<div className={'dz-col col m-b30'}><a><div >
													<div className="item-box shop-item style2">
														<div className="item-img">
															<Skeleton.Image active style={{ height: '300px', width: '210px' }} />
														</div>
														<div className="item-info text-center" style={{ minHeight: '120px' }}>
															<h4 className="item-title"><Skeleton.Input active /></h4>
															{/* <h5 className="price text-primary"><span>${parseFloat(allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size) && allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span></h5> */}
															<h5 className="price text-primary"><span><Skeleton.Input active /></span></h5>
															{/* <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div> */}
														</div>
													</div>

												</div>
												</a>
												</div>
											</div>
											<div className=''>
												<div className={'dz-col col m-b30'}><a><div >
													<div className="item-box shop-item style2">
														<div className="item-img">
															<Skeleton.Image active style={{ height: '300px', width: '210px' }} />
														</div>
														<div className="item-info text-center" style={{ minHeight: '120px' }}>
															<h4 className="item-title"><Skeleton.Input active /></h4>
															{/* <h5 className="price text-primary"><span>${parseFloat(allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size) && allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span></h5> */}
															<h5 className="price text-primary"><span><Skeleton.Input active /></span></h5>
															{/* <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div> */}
														</div>
													</div>

												</div>
												</a>
												</div>
											</div>
											<div className=''>
												<div className={'dz-col col m-b30'}><a><div >
													<div className="item-box shop-item style2">
														<div className="item-img">
															<Skeleton.Image active style={{ height: '300px', width: '210px' }} />
														</div>
														<div className="item-info text-center" style={{ minHeight: '120px' }}>
															<h4 className="item-title"><Skeleton.Input active /></h4>
															{/* <h5 className="price text-primary"><span>${parseFloat(allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size) && allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span></h5> */}
															<h5 className="price text-primary"><span><Skeleton.Input active /></span></h5>
															{/* <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div> */}
														</div>
													</div>

												</div>
												</a>
												</div>
											</div>
											<div className=''>
												<div className={'dz-col col m-b30'}><a><div >
													<div className="item-box shop-item style2">
														<div className="item-img">
															<Skeleton.Image active style={{ height: '300px', width: '210px' }} />
														</div>
														<div className="item-info text-center" style={{ minHeight: '120px' }}>
															<h4 className="item-title"><Skeleton.Input active /></h4>
															{/* <h5 className="price text-primary"><span>${parseFloat(allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size) && allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span></h5> */}
															<h5 className="price text-primary"><span><Skeleton.Input active /></span></h5>
															{/* <div className="cart-btn"><a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div> */}
														</div>
													</div>

												</div>
												</a>
												</div>
											</div>
										</div>
									</div>
								</>
								:
								<div className="row">
									{allProductDatas && allProductDatas.data && allProductDatas.data.length > 0 && allProductDatas.data.map((result, index) => {
										return <div className={'dz-col col m-b30'}><a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><div >
											<div className="item-box shop-item style2">
												<div className="item-img">
													<LazyLoadImage
														alt="Product Image"
														src={assestURL + result.image} // thumbnail is the URL of the image
														effect="blur" // Use blur effect for smoother loading transition
														placeholderSrc="..." // Placeholder image while loading
													/>
												</div>
												<div className="item-info text-center" style={{ minHeight: '120px' }}>
													<h4 className="item-title" style={(result.name.length > 15) ? { fontSize: '21px', overflowWrap: 'anywhere' } : { fontSize: '24px', overflowWrap: 'anywhere' }}>{result.name}</h4>
													<h5 className="price text-primary"><span>{parseFloat(allProductDatas.data[index].price.find(o => o.itemsize_id === result.default_size).points)} pts</span></h5>
													<div className="cart-btn"><a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover radius-xl"><i className="ti-eye"></i> View Details</a></div>
												</div>
											</div>

										</div>
										</a>
										</div>
									})
									}
								</div>
							}


						</div>
					</div>

				</div>
			</div>

			<Footer />

		</>

	)
}

export default RewardProductList;