import React from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

var img3 = require('./../../images/background/bg5.jpg');


function Policy() {
    window.scrollTo(0, 0)

    return (
        <>
            <Header />

            <div className="page-content bg-white">


                <div className="content-block">

                    <div className="section-full bg-white content-inner1" style={{ backgroundImage: "url(" + img3 + ")", backgroundSize: "100%", marginTop: '30px' }}>

                        <div className="tab-content product_list">
                            <div id="web-design-1" className="tab-pane active">
                                <p className="m-b0" style={{ fontSize: '20px' }}> Our Policy</p>
                            </div>
                        </div>

                        <div className="container" style={{ marginTop: '10px' }}>
                            <div class="row inner">
                                <div class="cnt_part">
                                    <div class="col-sm-12 about_us_part">
                                        <div class="col-sm-12">
                                            Protecting your private information is our priority. This Statement of Privacy applies to
                                            www.dipmenu.com and Velvet Cream and governs data collection and usage. For the purposes of
                                            this Privacy Policy, unless otherwise noted, all references to Velvet Cream include
                                            www.dipmenu.com and The Dip. The The Dip website is a self-service ordering site. By using the
                                            The Dip website, you consent to the data practices described in this statement.</div>
                                    </div>
                                    <br></br>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Use of your Personal Information</h5>
                                        <div class="col-sm-12">The Dip collects and uses your personal information to operate its website(s) and deliver the
                                            services you have requested.<br></br><br></br>
                                            The Dip may also use your personally identifiable information to inform you of other products or
                                            services available from The Dip and its affiliates.</div>
                                    </div>
                                    <br></br>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Location Data Usage</h5>
                                        <div class="col-sm-12">We use background location access to track your location only during active orders for food delivery purposes. Your location data is used to notify the shop for order preparation and ensure timely delivery.<br></br><br></br>
                                        </div>
                                        <div class="col-sm-12">
                                            - <b>Purpose:</b> To facilitate smooth order management and delivery.<br></br>
                                            - <b>Duration:</b> Location tracking stops immediately once your order is delivered.<br></br>
                                            - <b>Data Storage:</b> We do not store your location data after delivery.<br></br>
                                            - <b>Data Sharing:</b> Your location data is not shared with any unauthorized parties.<br></br>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Collection of your Personal Information</h5>
                                        <div class="col-sm-12">In order to better provide you with products and services offered on our Site, The Dip may collect
                                            personally identifiable information, such as your:</div>
                                        <div class="col-sm-12">
                                            - First and Last Name
                                            - Mailing Address
                                            - E-mail Address
                                            - Phone Number
                                        </div>
                                        <div class="col-sm-12">if you purchase The Dip's products and services, we collect billing and credit card information.
                                            This information is used to complete the purchase transaction.</div>
                                        <div class="col-sm-12">We do not collect any personal information about you unless you voluntarily provide it to us.
                                            However, you may be required to provide certain personal information to us when you elect to use
                                            certain products or services available on the Site. These may include: (a) registering for an account
                                            on our Site; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c)
                                            signing up for special offers from selected third parties; (d) sending us an email message; (e)
                                            submitting your credit card or other payment information when ordering and purchasing products
                                            and services on our Site. To wit, we will use your information for, but not limited to,
                                            communicating with you in relation to services and/or products you have requested from us. We
                                            also may gather additional personal or non-personal information in the future.</div>
                                    </div>
                                    <br></br>

                                    <div class="col-sm-12 about_us_part">
                                        <h5>Sharing Information with Third Parties</h5>
                                        <div class="col-sm-12">The Dip does not sell, rent or lease its customer lists to third parties.<br>
                                        </br>
                                            The Dip may share data with trusted partners to help perform statistical analysis, send you email or
                                            postal mail, provide customer support, or arrange for deliveries. All such third parties are
                                            prohibited from using your personal information except to provide these services to The Dip, and
                                            they are required to maintain the confidentiality of your information.<br />
                                            The Dip may disclose your personal information, without notice, if required to do so by law or in
                                            the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply
                                            with legal process served on The Dip or the site; (b) protect and defend the rights or property of
                                            The Dip; and/or (c) act under exigent circumstances to protect the personal safety of users of The
                                            Dip, or the public.
                                        </div>
                                    </div>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Automatically Collected Information</h5>
                                        <div class="col-sm-12">
                                            Information about your computer hardware and software may be automatically collected by The
                                            Dip. This information can include: your IP address, browser type, domain names, access times and
                                            referring website addresses. This information is used for the operation of the service, to maintain
                                            quality of the service, and to provide general statistics regarding use of the The Dip website.<br />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Use of Cookies</h5>
                                        <div class="col-sm-12">
                                            The The Dip website may use "cookies" to help you personalize your online experience. A cookie
                                            is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
                                            programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only
                                            be read by a web server in the domain that issued the cookie to you.<br />
                                            One of the primary purposes of cookies is to provide a convenience feature to save you time. The
                                            purpose of a cookie is to tell the Web server that you have returned to a specific page. For
                                            example, if you personalize The Dip pages, or register with The Dip site or services, a cookie
                                            helps The Dip to recall your specific information on subsequent visits. This simplifies the process of
                                            recording your personal information, such as billing addresses, shipping addresses, and so on.
                                            When you return to the same The Dip website, the information you previously provided can be
                                            retrieved, so you can easily use the The Dip features that you customized.<br />
                                            You have the ability to accept or decline cookies. Most Web browsers automatically accept
                                            cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you
                                            choose to decline cookies, you may not be able to fully experience the interactive features of the
                                            The Dip services or websites you visit.<br />
                                        </div>
                                    </div>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Security of your Personal Information</h5>
                                        <div class="col-sm-12">
                                            The Dip secures your personal information from unauthorized access, use, or disclosure. The Dip
                                            uses the following methods for this purpose:<br />
                                            - SSL Protocol<br />
                                            When personal information (such as a credit card number) is transmitted to other websites, it is
                                            protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.<br />
                                            We strive to take appropriate security measures to protect against unauthorized access to or
                                            alteration of your personal information. Unfortunately, no data transmission over the Internet or any
                                            wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
                                            your personal information, you acknowledge that: (a) there are security and privacy limitations
                                            inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any
                                            and all information and data exchanged between you and us through this Site cannot be
                                            guaranteed.
                                        </div>
                                    </div>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Children Under Thirteen</h5>
                                        <div class="col-sm-12">
                                            The Dip does not knowingly collect personally identifiable information from children under the age
                                            of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for
                                            permission to use this website.
                                        </div>
                                    </div>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Changes to this Statement</h5>
                                        <div class="col-sm-12">
                                            The Dip reserves the right to change this Privacy Policy from time to time. We will notify you about
                                            significant changes in the way we treat personal information by sending a notice to the primary
                                            email address specified in your account, by placing a prominent notice on our site, and/or by
                                            updating any privacy information on this page. Your continued use of the Site and/or Services
                                            available through this Site after such modifications will constitute your: (a) acknowledgment of the
                                            modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.
                                        </div>
                                    </div>
                                    <br></br>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Questions or Comments</h5>
                                        <div class="col-sm-12">
                                            The Dip welcomes your questions or comments regarding this Statement of Privacy. If you believe
                                            that The Dip has not adhered to this Statement, please contact The Dip at:<br></br>
                                            Velvet Cream
                                            2290 Hwy 51 S
                                            Hernando, Mississippi 38632<br />
                                            Email Address:
                                            velvetcream@gmail.com<br />
                                            {/* Telephone number:
662-429-6540<br/> */}
                                            Effective as of January 22, 2020
                                        </div>
                                    </div>
                                    <br></br>
                                    <div class="col-sm-12 about_us_part">
                                        <h5>Refund/Cancellation Policy</h5>
                                        <div class="col-sm-12">
                                            Once payment has been processed and received by the merchant (Velvet Cream or The Dip), there will be no refunds or cancellations processed. Submittal of payment is considered intent to pay for the items “you” have selected in your cart and have processed for payment.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>

            </div>

            <Footer />
        </>
    )
}

export default Policy;