// YourComponent.js

// YourComponent.js

import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const Popup = ({ openModal, contentValue, setOpenModal }) => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        console.log('Content has changed:', contentValue);
    }, [contentValue])

    useEffect(() => {
        if (openModal === true) {
            openAlert()
        }
        else {
            closeAlert()
        }

    }, [openModal])

    const openAlert = () => {
        setShowAlert(true);
    };

    const closeAlert = () => {
        setShowAlert(false);
        setOpenModal(false)
    };

    // Your if-else condition
    const condition = true; // Replace with your actual condition

    return (
        <div>
            {/* Render the alert based on showAlert state */}
            {showAlert && (
                <div
                    style={{
                        position: 'fixed',
                        top: '35%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        backgroundColor: '#f0d5d3', // Background color
                        border: '1px solid #c23629', // Border color
                        // backgroundColor: '#fff',
                        // border: '1px solid #ccc',
                        zIndex: '2', // Ensure it appears above the main modal
                    }}
                >

                    <div style={{ textAlign: 'center' }}>
                        <p style={{ marginTop: '10px' }}><ExclamationCircleOutlined style={{ fontSize: '22px', color: 'red' }} />&nbsp; {contentValue}</p>
                        <CloseCircleOutlined
                            style={{ position: 'absolute', top: '12px', right: '10px', fontSize: '25px', cursor: 'pointer' }}
                            onClick={closeAlert}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Popup;

