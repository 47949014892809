import React, { useEffect, useState, useRef } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { giftcardData, userData, profileData, RedeemCouponData, profileSelector } from "../../store/reducer/profile";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import SimpleReactValidator from 'simple-react-validator';
import { assestURL, secretKey } from '../../services/config';
import { CardType, generalSelector } from "../../store/reducer/general";
import { addToWallet, newPayment, orderSelector } from '../../store/reducer/order';
import Swal from 'sweetalert2'
import moment from 'moment';
import { Button, Modal } from "react-bootstrap";
import { SHA512 } from 'crypto-js';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import queryString from 'query-string';

function BuyGiftcard() {
	const formValidator = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	let userDetails = JSON.parse(localStorage.getItem('userdata'));

	const location = useLocation();
	let params = queryString.parse(location.search);

	const [cardtypeImage, setcardtypeImage] = useState('')
	const { cardtypeDatas, cardtypeLoading } = useSelector(generalSelector);
	if (!userDetails) {
		// dispatch(logOut());
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}
	const [loginUser] = useState(userDetails.id);
	const [formDetails, setformDetails] = useState(
		{ email: userDetails?.email, amount: '', cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' });
	const { giftDatas, userDatas, redeemDatas, redeemloading, userloading, profileDatas, updateLoading, profileerrorMessage } = useSelector(profileSelector);
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const { paymentloading, paymentMessage, paymentDatas } = useSelector(orderSelector);
	const [displayFakeData, setdisplayFakeData] = useState(0)
	const [walletAmount, setwalletAmount] = useState(0)
	const [TransactionID, setTransactionID] = useState(null);
	const [showHistory, setshowHistory] = useState(false);
	const [showRedeemModal, setshowRedeemModal] = useState(false);
	const [redeemDetails, setRedeemDetails] = useState({ coupon_code: "" });
	const [errorMessage, seterrorMessage] = useState('');
	const [TopupData, setTopupData] = useState([]);
	const [RedeemData, setRedeemData] = useState([]);
	const [activeTab, setactiveTab] = useState(2)

	const onCloseRedeem = () => {
		seterrorMessage('')
		setshowRedeemModal(false);
		window.scroll({ top: 0, left: 0, behavior: "smooth", });
	}

	const showRedeemPopup = () => {
		setshowRedeemModal(true);
	}

	const AddWalletAmount = () => {

		if (redeemDetails.coupon_code.trim() == '') {
			seterrorMessage('Please enter the coupon code');
			return false;
		}

		let postData = { redeem_code: redeemDetails.coupon_code }

		dispatch(
			RedeemCouponData({ postData })
		);

	};

	const BuyNewCard = (e) => {
		e.preventDefault();
		setAddFormSubmit(true);


		if (formValidator.current.allValid()) {

			let paymentData = { channel: 'WEB', autoCapture: true, processAsSale: false }

			paymentData.order = {
				orderId: 'buy',
				totalAmount: parseFloat(formDetails.amount).toFixed(2),
				description: 'buy gift cards for the user',
				currency: 'USD',
				orderBreakdown: {
					subtotalAmount: parseFloat(formDetails.amount).toFixed(2),
					items: [{ "unitPrice": formDetails.amount, "quantity": 1 }]
				}
			}
			paymentData.customerAccount = {
				payloadType: "KEYED",
				accountType: "CHECKING",
				cardDetails: {
					dataFormat: "PLAIN_TEXT",
					cardNumber: formDetails.cc_number.replaceAll(" ", ""),
					cvv: formDetails.cc_cvc,
					expiryDate: formDetails.cc_month.replace('/', '')
				}
			}

			dispatch(newPayment({ postData: paymentData }));

		}
	};

	const getProfileDispatch = (loginUser) => { dispatch(profileData({ user_id: loginUser })) };
	const userDispatch = (loginUser) => { dispatch(userData({ user_id: loginUser })) };
	const giftCardDispatch = (loginUser) => { dispatch(giftcardData({ user_id: loginUser })) };

	useEffect(() => {
		giftCardDispatch(loginUser)
		getProfileDispatch(loginUser)
		userDispatch(loginUser)
	}, [])

	const displayFakeNumber = () => {
		if (formDetails.cc_number.length > 4)
			setdisplayFakeData(1)
	}

	const displayNumber = () => {
		setdisplayFakeData(0)
	}

	useEffect(() => {
		if (cardtypeDatas) {

			console.log(cardtypeDatas, 'cardtypeDatas');
			if (cardtypeLoading === API_STATUS.FULFILLED) {
				if (cardtypeDatas.status == true) {

					if (cardtypeDatas?.result.cardType == 'VISA' || cardtypeDatas?.result.cardType == 'VISA DEBIT') {
						setcardtypeImage(require('../../images/visa.jpg'));
					}
					if (cardtypeDatas?.result.cardType === 'ELECTRON') {
						setcardtypeImage(require('../../images/electron.jpeg'));
					}
					if (cardtypeDatas?.result.cardType === 'MAESTRO') {
						setcardtypeImage(require('../../images/Maestro.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DISCOVER') {
						setcardtypeImage(require('../../images/discover.png'));
					}
					if (cardtypeDatas?.result.cardType === 'JCB') {
						setcardtypeImage(require('../../images/jcb.png'));
					}
					if (cardtypeDatas?.result.cardType === 'DEBIT MASTERCARD') {
						setcardtypeImage(require('../../images/Mastercard.png'));
					}

					if (cardtypeDatas?.result?.cardType == undefined) {
						setcardtypeImage('')
					}
				}
			}

			if (cardtypeLoading === API_STATUS.PENDING) {
				toast.dismiss();
			}

		}
	}, [cardtypeDatas])

	useEffect(() => {
		formValidator.current.showMessages()
	}, [])


	useEffect(() => {
		if (userDatas) {
			setwalletAmount(userDatas?.data[0].wallet)
			console.log(userDatas, 'userDatas');
		}
	}, [userDatas])

	useEffect(() => {
		if (giftDatas) {
			console.log(giftDatas, 'giftDatas');
			giftDatas.data.map((gifts, key) => {
				if (gifts.redeem_flag == 1) {
					RedeemData.push(gifts)
				} else {
					TopupData.push(gifts)
				}
			})
		}

	}, [giftDatas])

	const cc_format = (value) => {
		const v = value
			.replace(/\s+/g, "")
			.replace(/[^0-9]/gi, "")
			.substr(0, 16);
		const parts = [];

		for (let i = 0; i < v.length; i += 4) {
			parts.push(v.substr(i, 4));
		}

		return parts.length > 1 ? parts.join(" ") : value;
	}

	useEffect(() => {
		if (paymentloading === API_STATUS.FULFILLED) {
			if (paymentDatas) {
				if (paymentDatas.status === false) {
					Swal.fire({
						title: 'Payment Failed!',
						icon: 'error',
						text: paymentDatas.message,
						cancelButtonColor: '#d33',
						cancelButtonText: 'Retry Payment',
						showConfirmButton: false,
						showCancelButton: true
					}).then((result) => {
						if (result.dismiss) {
							setformDetails({ ...formDetails, email: '', amount: 0, cc_name: '', cc_number: '', cc_cvc: '', cc_month: '' })
							setdisplayFakeData(0)
							setcardtypeImage('')
						}
					})
					// return false;
				}
				else {
					setTransactionID(paymentDatas.data.uniqueReference)

					let newgiftcard = {
						amount: formDetails.amount,
						user_id: loginUser,
						code: paymentDatas.data.uniqueReference,
						sender_id: loginUser,
						redeem_flag: 0
					}
					dispatch(addToWallet({ postData: newgiftcard }));

					// setshowModal(true);
					Swal.fire({
						title: 'Payment Successful!',
						icon: 'success',
						html: `Amount has been added to your Dip Wallet.`,
						// timer: 2000,
						timerProgressBar: true,
						// footer: 'Please wait Confirming your Order...',
						// showConfirmButton: false,
						confirmButtonText: 'Close',
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.href = '/dipwallet'
						}
					})

				}


			}
			// window.scrollTo(0, 0)
		} else if (paymentloading === API_STATUS.PENDING) {
			console.log('erorr')
		}
		if (paymentloading === API_STATUS.REJECTED) {

		}
	}, [paymentloading, paymentDatas])

	useEffect(() => {
		console.log(redeemloading, "redeemloading")
		if (redeemloading === API_STATUS.FULFILLED) {
			seterrorMessage('');
			if (redeemDatas.status == true) {
				setshowRedeemModal(false)
				toast.dismiss();
				toast.success('Coupon amount added to your wallet successfully.!');

				window.setTimeout(function () {
					window.location.reload()
				}, 2000);
			} else {
				setshowRedeemModal(true)
				// console.log(re);
				seterrorMessage(redeemDatas.message);
				setRedeemDetails({ ...redeemDetails, coupon_code: '' })
			}

		}
		if (redeemloading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (redeemloading === API_STATUS.REJECTED) {
			toast.dismiss();
			// setshowRedeemModal(false)
			seterrorMessage('Something went wrong.');
			setRedeemDetails({ ...redeemDetails, coupon_code: '' })

		}
	}, [redeemloading]);

	const toggleChecked = (e) => {
		// let activate = (e.target.value == 1) ? 2 : 1
		setactiveTab(e.target.value);
	}

	const submitGoogle = (e) => {
		if (formDetails.amount <= 0) {
			e.preventDefault();
			toast.error('Amount is required.!')
			return false;
		}
	}

	useEffect(() => {

	}, [activeTab])

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="section-full mt-4 shop-account">
					<div className="container">
						<div className="row">
							<div className="col-lg-9" style={{ paddingBottom: '10px' }}>
								<a href="/newgiftcard" class="btn btn-primary btn-xs" style={{ marginRight: '10px' }} >Buy Gift Card</a>
								<a href="#" class="btn btn-primary btn-xs" onClick={showRedeemPopup} style={{ marginRight: '10px' }}>Redeem Code</a>&nbsp;
								<a href="#" class="btn btn-success btn-xs" style={{ marginRight: '10px' }} onClick={val => {
									setshowHistory(false)

								}}>Dip Wallet</a>&nbsp;
								<a href={'/viewhistory'} class="btn btn-primary btn-xs" style={{ marginRight: '10px' }} >View History</a>

								{/* </div>
					<div className="col-lg-4" style={{ paddingTop: '0.5rem', paddingLeft: '16rem'}}> */}

								{/* </div> */}
								{/* </div> */}
							</div>
							<div className="col-lg-3">
								<h3 className="m-b5 pull-right">Balance: $ {parseFloat(walletAmount).toFixed(2)} </h3>
							</div>
						</div>


						<div className="row" style={(showHistory) ? { display: 'none' } : { display: 'block' }}>
							<div className="col-lg-12">
								<div className="p-a30 border-1 m-auto radius-sm">
									<div className="tab-content">
										<form id="login" className="tab-pane active">

											<div className='row'>

												<div className="form-group col-6">
													<label>Email </label>
													<input
														className="form-control"
														name='email'
														placeholder="Enter the Email"
														type="email"
														value={formDetails.email}
														autoComplete="off"
														readOnly={true}
														onChange={val => {
															setformDetails({ ...formDetails, email: val.target.value });
														}}
													/>
												</div>
												<div className="form-group col-6">
													<label>Amount <span style={{ color: 'red' }}>*</span> </label>
													<input
														className="form-control"
														name='amount'
														placeholder="Enter the Amount"
														type="text"
														value={formDetails.amount}
														autoComplete="off"
														onChange={val => {

															if (val.target.value.length > 8) {
																return false;
															}

															var numbers = /^[0-9]*\.?\d*$/;

															if (val.target.value.match(numbers) || val.target.value == '') {
																setformDetails({ ...formDetails, amount: val.target.value });
															} else {
																return false;
															}
														}}
													/>
													{formValidator.current.message(
														'amount',
														formDetails.amount,
														'required',
														{
															className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter amount',
															}
														}
													)}
												</div>

												{/* <div className='form-group col-12'>

												</div> */}
												{/* <div className='form-group col-9'>
													<div className="btn-group product-item-size" data-toggle="buttons">
														<label className={activeTab == 2 ? "btn active" : 'btn'} >
															<input name="options" id="option8" type="radio" value="2" onClick={toggleChecked} /> Pay with Card
														</label>
														<label className={activeTab == 1 ? "btn active" : 'btn'} >
															<input name="options" id="option7" type="radio" value="1" onClick={toggleChecked} /> Others
														</label>

													</div>
												</div>
												{activeTab == 2 ?
													<><div className="form-group col-6">
														<label>CardHolder Name <span style={{ color: 'red' }}>*</span> </label>

														<input type="text" value={formDetails.cc_name} className="form-control"
															placeholder="Card Holder Name"
															name='cc_name'
															onChange={val => {

																var matches = val.target.value.match(/\d+/g);
																if (matches != null) {
																	return false
																}
																setformDetails({ ...formDetails, cc_name: val.target.value });
															}}
														/>
														{formValidator.current.message(
															'cc_name',
															formDetails.cc_name,
															'required|alpha_space',
															{
																className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter Name',
																	alpha_space: 'Please Enter Valid Name',
																}
															}
														)}
													</div>
														<div className="form-group col-6">
															<label>Card Number <span style={{ color: 'red' }}>*</span> </label>

															<input type="text" value={(displayFakeData == 0 || formDetails.cc_number == '') ? cc_format(formDetails.cc_number) : ('****' + formDetails.cc_number.substr(-4))} onBlur={displayFakeNumber} onFocus={displayNumber} className="form-control"
																placeholder="Card number"
																name='cc_number'
																onPaste={(e) => {
																	e.preventDefault()
																	return false;
																}}
																onCopy={(e) => {
																	e.preventDefault()
																	return false;
																}}
																onChange={val => {

																	let name = val.target.value;
																	var format = /^[0-9\s]*$/;
																	if (format.test(name)) {
																		setformDetails({ ...formDetails, cc_number: val.target.value });

																	} else {
																		return false
																	}

																	console.log(val.target.value, 'val.target.value');
																	let type = val.target.value.replaceAll(" ", "")
																	if (type.length == 16) {
																		let type = val.target.value.replaceAll(" ", "")
																		// dispatch(
																		// 	CardType({ card_id: type })
																		// )
																	} else {
																		setcardtypeImage('')
																	}


																	// if (name.length > 16) {
																	// 	return false
																	// }

																	// var regExp = /[a-zA-Z]/g;

																	// 	const joy = val.target.value.toString().replace(/\d{2}(?=.)/g, '$& ');
																	// joy.join(' ');
																	//let name = val.target.value.toString().replace(/.{1,4}(?=(.{4})+$)/g, '$& ')

																}}
															/>
															{formValidator.current.message(
																'cc_number',
																formDetails.cc_number,
																'required|card_num',
																{
																	className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																	messages: {
																		required: 'Please Enter Card Number',
																		card_num: 'Please Enter Valid Card Number',
																	}
																}
															)}
														</div>
														<div className="form-group col-6">
															<label>Expiration Month & Year <span style={{ color: 'red' }}>*</span> </label>
															<input type="text" value={formDetails.cc_month} className="form-control"
																placeholder="MM/YY"
																name='cc_month'
																onChange={val => {

																	if (val.target.value == '/') {
																		setformDetails({ ...formDetails, cc_month: '' });
																		return false
																	}
																	console.log(val.target.value, val.target.value.length, new Date().getFullYear().toString().substr(-2), 'val.target.value');
																	if (val.target.value.length > 5) {
																		return false;
																	}
																	if (val.target.value.length) {
																		let data = val.target.value.split("/")

																		if (data[0] > 12) {
																			// if (data[0] > 12 || ( data[0].length == 1 && data[0] < 1)) {
																			return false
																		}
																		let year = new Date().getFullYear().toString().substr(-2);
																		console.log(year, 'year');

																		if (data.length > 1) {
																			if (data[1].length == 2) {
																				if (year === data[1] || (data[1] <= (Number(year) + Number(20)))) {

																				} else {
																					return false
																				}
																			}

																			if (data[1].length > 2) {
																				return false
																			}
																		}
																		//  && (Number(data[1])) > new Date().getFullYear().toString().substr(-2)){
																		// 	return false;
																		// }

																	}

																	var regExp = /[a-zA-Z]/g;
																	if (regExp.test(val.target.value)) {
																		return false
																	}


																	if (val.target.value.length === 2 && val.target.value > 12) {
																		return false;
																	}
																	console.log(val.target.value.indexOf('/'), 'val.target.value--1');
																	if (val.target.value.length === 2) {
																		if (val.target.value.indexOf('/') !== -1) {
																			let replacevalue = val.target.value.replace("/", '')
																			if (replacevalue > 0 && val.target.value != '1/') {
																				setformDetails({ ...formDetails, cc_month: "0" + val.target.value });
																			} else {
																				setformDetails({ ...formDetails, cc_month: val.target.value });
																			}

																			return false
																		}
																		val.target.value = val.target.value + '/'
																	}
																	else {
																		// if (val.target.value.length === 3 && val.target.value.charAt(2) === '/')
																		// val.target.value = val.target.value.replace('/', '');
																	}

																	if (val.target.value.length === 4 && val.target.value.charAt(3) < 2) {
																		return false
																	}
																	if (val.target.value.length === 5) {
																		let third = val.target.value.charAt(3)
																		let fourth = val.target.value.charAt(4)
																		let numbers = third + fourth
																		console.log(numbers, 'numbers-');
																		let currentyear = new Date().getFullYear().toString().substr(-2)
																		if (Number(numbers) < currentyear)
																			return false
																	}
																	let updateValue = val.target.value;

																	if (updateValue.length === 1 && (updateValue != 1 && updateValue != 0)) {
																		updateValue = "0" + updateValue;
																		updateValue = updateValue + '/'
																	}
																	// console.log(val.target.value, 'val.target.value---2');
																	setformDetails({ ...formDetails, cc_month: updateValue });
																}}
															/>
															{formValidator.current.message(
																'cc_month',
																formDetails.cc_month,
																'required|card_exp',
																{
																	className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																	messages: {
																		required: 'Please Enter Card Expiry Month & Year',
																		card_exp: 'Please Enter Valid Month & Year (MM/YY)',
																	}
																}
															)}
														</div>
														<div className="form-group col-6">
															<label>CVV Number <span style={{ color: 'red' }}>*</span> </label>

															<input type="password" value={formDetails.cc_cvc} className="form-control"
																placeholder="Card Verification Number"
																name='cc_cvc'
																onChange={val => {
																	if (val.target.value.length > 4) {
																		return false;
																	}
																	var numbers = /^[0-9]+$/;
																	if (val.target.value.match(numbers) || val.target.value == '') {
																		setformDetails({ ...formDetails, cc_cvc: val.target.value });
																	} else {
																		return false;
																	}

																}}
															/>
															{formValidator.current.message(
																'cc_cvc',
																formDetails.cc_cvc,
																'required|integer|min:3|max:7',
																{
																	className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																	messages: {
																		required: 'Please Enter CVV',
																		integer: 'Please Enter Valid Number',
																		min: 'CVV Number must be at least 3 characters',
																		max: 'CVV Number should not greater than 7 characters',
																	}
																}
															)}

														</div>
														<div className="form-group col-6">
															<a href='javascript:;' className="btn btn-success w-25" onClick={BuyNewCard} style={{ color: 'white' }} >Submit</a>&nbsp;
															<a href='#' className="btn btn-danger w-25" >Back</a>
														</div></> */}


												{/* `https://testpayments.worldnettps.com/merchant/paymentpage?TERMINALID=${process.env.REACT_APP_TERMINAL}&ORDERID=300145858&CURRENCY=USD&DATETIME=${moment().format('MM-DD-YYYY:HH:mm:ss:SSS')}&PAGES:DIPWALLET&AMOUNT=${parseFloat(formDetails.amount).toFixed(2)}&HASH=${CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:300145858:${parseFloat(formDetails.amount).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)}`} method="post" */}

												<div className="form-group col-4">
													<h5>Proceed To Pay</h5>
													<form action={process.env?.REACT_APP_PAYMENT_PAGE ? process.env.REACT_APP_PAYMENT_PAGE :
														"https://testpayments.worldnettps.com/merchant/paymentpage"}>
														<input type="hidden" name="TERMINALID" value={process.env.REACT_APP_TERMINAL} />
														<input type="hidden" name="ORDERID" value={moment().format('YYYY-MM-DD HHmmss')} />
														<input type="hidden" name="CURRENCY" value="USD" />
														<input type="hidden" name="PAGES" value="DIPWALLET" />
														<input type="hidden" name="AMOUNT" value={parseFloat(formDetails.amount).toFixed(2)} />
														<input type="hidden" name="DATETIME" value={moment().format('MM-DD-YYYY:HH:mm:ss:SSS')} />
														<input type="hidden" name="HASH" value={CryptoJS.SHA512(`${process.env.REACT_APP_TERMINAL}:${moment().format('YYYY-MM-DD HHmmss')}:${parseFloat(formDetails.amount).toFixed(2)}:${moment().format("MM-DD-YYYY:HH:mm:ss:SSS")}:${process.env.REACT_APP_WORLDNET_SECRET}`).toString(CryptoJS.enc.Hex)} />
														<input className={(formDetails.amount > 0) ? 'btn button-warning btn-block' : 'btn btn-danger  btn-block'} type={(formDetails.amount > 0) ? "submit" : "button"} onClick={submitGoogle} value={(formDetails.amount > 0) ? `Pay $ ${parseFloat(formDetails.amount).toFixed(2)}` : 'Enter Amount To Pay'} />
													</form>
												</div>
											</div>

										</form>
									</div>
								</div>
							</div>
						</div><br></br>

					</div>
				</div >
				<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="xl" show={showRedeemModal} onHide={onCloseRedeem}>
					<Modal.Header closeButton>
						<Modal.Title>Redeem Code</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="form-group">
							<label >Coupon Code<span style={{ color: 'red' }}>*</span></label>
							<input
								className="form-control"
								id="signin-email"
								placeholder="Please enter coupon code"
								type="text"
								value={redeemDetails.coupon_code}
								autoComplete="off"
								onChange={val => {
									if (val.target.value.length) {
										seterrorMessage('')
									} else {
										seterrorMessage('Please enter the coupon code')
									}
									setRedeemDetails({ ...redeemDetails, coupon_code: val.target.value });
								}}
							/>
							<span style={{ color: 'red' }}>{errorMessage}</span>
						</div>

						<Button className="btn btn-primary btn-block1" href="javascript:void(0);" style={{width:'115px'}} onClick={AddWalletAmount}
						>Redeem</Button>&nbsp;&nbsp;

						<Button variant="secondary" onClick={onCloseRedeem} style={{width:'115px'}}>
							Close
						</Button>
					</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>
			</div >
			<Footer />
		</>

	)
}

export default BuyGiftcard;
