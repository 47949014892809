import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logOut } from "../../store/reducer/login"
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { clearInactiveItemCartData, categoryData, clearCartData, clearProductData, clearCategoryData, categorySelector } from '../../store/reducer/category';
import { cartData, ProductSelector } from '../../store/reducer/product';
import { Toaster } from 'react-hot-toast';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from '../../services/config';
import { generalData } from "../../store/reducer/general";


function Header() {

	// let userDetails = JSON.parse(localStorage.getItem('userdata'));

	let temp
	let temporaryToken = localStorage.getItem('temporaryToken')
	// if(userDetails?.id){
	// 	//localStorage.removeItem('temporaryToken')
	// }else{
	if (!temporaryToken) {
		temp = Math.random().toString(20).substr(2, 15);
		localStorage.setItem('temporaryToken', temp)
	}

	// }
	console.log(temporaryToken, 'temporaryToken');
	// toast.success('Logged out successfully.!');
	const dispatch = useDispatch();
	const { categoryDatas } = useSelector(categorySelector);
	const { cartDatas } = useSelector(ProductSelector);
	// const [cartCount, setcartCount] = useState(0);
	// render(){
	const logout = () => {
		// alert('ok');
		toast.success('Logged out successfully.!');

		dispatch(logOut());
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}


	const categoryDispatch = () => {
		dispatch(
			categoryData({
				// query: searchQuery,
				// page: page + 1,
				// limit: rowsPerPage,
				// sortby: sortBy,
				// order: order,
			})
		);
	};

	const getCartDispatch = () => {
		dispatch(
			cartData({})
		);
	};

	const clearCartDispatch = () => {
		dispatch(
			clearCartData({})
		);
	};
	const clearCategoryCartDispatch = () => {
		dispatch(
			clearCategoryData({})
		);
	};
	const clearProductCartDispatch = () => {
		dispatch(
			clearProductData({})
		);
	};
	const clearInactiveItemFromCartDispatch = () => {
		dispatch(
			clearInactiveItemCartData({})
		);
	};

	const GeneralDispatch = () => {
		dispatch(
			generalData({})
		);
	};
	useEffect(() => {
		categoryDispatch()
		clearCartDispatch()
		clearCategoryCartDispatch()
		clearProductCartDispatch()
		getCartDispatch()
		clearInactiveItemFromCartDispatch()
		GeneralDispatch()
	}, [])



	return (
		<header className="site-header header center mo-left header-style-2" style={{ position: 'sticky', top: '0' }}>

			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container clearfix">
						<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>

						<div className="header-nav navbar-collapse navbar myNavbar collapse justify-content-between" id="navbarNavDropdown">
							<div className="logo-header mostion">
								<Link to={'/'} className="dez-page"><img src={require("./../../images/horizon-full.svg")} alt="" /></Link>
							</div>
							<Link to={'/'} className="dez-page"><img src={require('./../../images/logos/app.png')} alt="" /></Link>

							{localStorage.getItem('isAuthenticated') ? <>
								<ul className="nav navbar-nav nav1" style={{ marginLeft: '198px' }}>
									<li><Link to={'/'}>Home</Link></li>
									<li>
										<Link to={''}>Menu<i className="fa fa-chevron-down"></i></Link>
										<ul className="sub-menu">
											{(categoryDatas && categoryDatas.data) ? categoryDatas.data.map((result) => {
												return <li><a href={(`/subcategory/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)}>{result.name}</a></li>
											}) : ''}
										</ul>
									</li>
									<li><Link to={'/products'}>Products</Link></li>
								</ul>
								<ul className="nav navbar-nav nav2" style={{ width: "26%" }}>

									<li><Link to={'/contactus'}>Contact Us</Link></li>
									<li><Link to={'/aboutus'}>About Us</Link></li>
									{
										(localStorage.getItem('isAuthenticated') != 'true') ? <><li><Link to={'/register'}>Register</Link></li>
											<li><Link to={'/login'}>Login</Link></li> </> : <li><Link to={'#'}><i className="fa fa-user" aria-hidden="true" style={{ zoom: '200%' }}></i></Link>
											<ul className="sub-menu">
												<li><a href={'/profile'}>Profile</a></li>
												<li><a href={'/newgiftcard'}>Gift Cards</a></li>
												<li><a href={'/dipwallet'}>Dip Wallet</a></li>
												<li><a href={'/rewardproducts'}>Reward Products</a></li>
												<li><a href={'/favourites'}>My Favorites</a></li>
												<li><a href={'/orderlist'}>My Orders</a></li>
												<li><a href="javascript:void(0)" onClick={logout} to={'/'}> Logout</a></li>
											</ul>
										</li>
									}
									<li style={{ position: 'relative' }}><Link to={'/cartlist'}><i className="fa fa-shopping-cart" aria-hidden="true" style={{ zoom: '200%' }} ></i><sup className='cart-count'>{(cartDatas) ? cartDatas.count : ''}</sup></Link></li>


									{/* {(localStorage.getItem('isAuthenticated') == 'true') ? <li><a href="javascript:void(0)" onClick={logout}  to={''}> Logout</a></li> : ''} */}
									{/* <li><a href="javascript:void(0)" onClick={logout}  to={''}> Logout</a></li> */}

								</ul></>
								: <>
									<ul className="nav navbar-nav nav1" style={{ marginLeft: '87px' }}>
										<li><Link to={'/'}>Home</Link></li>
										<li>
											<Link to={''}>Menu<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												{(categoryDatas && categoryDatas.data) ? categoryDatas.data.map((result) => {
													return <li><a href={(`/subcategory/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)}>{result.name}</a></li>
												}) : ''}
											</ul>
										</li>
										<li><Link to={'/products'}>Products</Link></li>
									</ul>
									<ul className="nav navbar-nav nav2">

										<li><Link to={'/contactus'}>Contact Us</Link></li>
										<li><Link to={'/aboutus'}>About Us</Link></li>
										{
											(localStorage.getItem('isAuthenticated') != 'true') ? <>
												<li><Link to={'/login'}>Login</Link></li> </> : <li><Link to={''}><i className="fa fa-user" aria-hidden="true" style={{ zoom: '200%' }}></i></Link>
												<ul className="sub-menu">
													<li><a href={'/profile'}>Profile</a></li>
													<li><a href="javascript:void(0)" onClick={logout} to={'/'}> Logout</a></li>
												</ul>
											</li>
										}
										<li style={{ position: 'relative' }}><Link to={'/cartlist'}><i className="fa fa-shopping-cart" aria-hidden="true" style={{ zoom: '200%' }} ></i><sup className='cart-count'>{(cartDatas) ? cartDatas.count : ''}</sup></Link></li>


										{/* {(localStorage.getItem('isAuthenticated') == 'true') ? <li><a href="javascript:void(0)" onClick={logout}  to={''}> Logout</a></li> : ''} */}
										{/* <li><a href="javascript:void(0)" onClick={logout}  to={''}> Logout</a></li> */}

									</ul></>}

							{/* { (auth ) ? <ul className="nav navbar-nav nav2"><li><Link to={'/register'}>Register</Link></li> <li><Link to={'/login'}>Login</Link></li></ul>: <ul className="nav navbar-nav nav2"><li><Link to={''}>Contact Us</Link></li></ul>
								} */}
							{/* <li>
										<Link to={''}>Blog<i className="fa fa-chevron-down"></i></Link>
										<ul className="sub-menu">
											 <li><Link to={'/register'}>Register</Link></li>
											<li><Link to={'/login'}>Login</Link></li> 
										</ul>
									</li>
									<li><Link to={''}>Contact Us</Link>
									</li> */}
							{/* </ul>		 */}
						</div>
					</div>
				</div>
			</div>
			<>
				<Toaster
					toastOptions={{
						success: {
							style: {
								// backgroundColor: 'green'
							},
						},
						error: {
							style: {
								backgroundColor: 'red',

							},
						},
					}}
					auto-hide='off'
					position="top-right"
					reverseOrder={true}
				/></>
		</header>

	)
	// }

}

export default Header;