import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GoogleMaps from "simple-react-google-maps";
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { contactUsData, ContactSelector } from '../../store/reducer/contactus';
import { useState } from 'react';
import _ from 'lodash';

var bgimg1 = require('./../../images/banner/bnr1.jpg');
var bgimg2 = require('./../../images/background/bg5.jpg');

function Contact() {
	const detectDeviceType = () =>
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
			? 'Mobile'
			: 'Desktop';

	if (detectDeviceType() == 'Mobile') {
		window.location.href = 'mobilepage'
	}

	window.scrollTo(0, 0)
	const dispatch = useDispatch();
	const { contactDatas } = useSelector(ContactSelector);
	const [orderedContactDatas,setOrderedContactDaatas] = useState([])

	const contactUsDispatch = () => {
		dispatch(
			contactUsData({})
		);
	};

	useEffect(() => {
		contactUsDispatch()
	}, [])

	useEffect(()=>{
		if(contactDatas && contactDatas.data && contactDatas.data.length && contactDatas.data.length > 0){
			const reorderedArray = _.orderBy(contactDatas.data, ['id'], ['asc']);	
			setOrderedContactDaatas(reorderedArray)
		}

	},[contactDatas])


	const MapData = () => {
		return <GoogleMaps
			apiKey={"AIzaSyBbHk3eFodSk_DSGzv8dd_dIJpZvVDyg4s"}
			style={{ height: "350px", width: "100%" }}
			zoom={6}
			center={{ lat: 34.8263748, lng: -89.9941414 }}
			markers={{ lat: 34.8263748, lng: -89.9941414 }} //optional
		/>
	}
	console.log(contactDatas, 'contactDatas');
	return (
		<>
			<Header />
			<div className="page-content bg-white">
				{/* <div className="dlab-bnr-inr overlay-black-middle" style={{backgroundImage: "url(" + bgimg1 + ")"}}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Contact</h1>
							
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'}><i className="fa fa-home"></i></Link></li>
									<li>Contact</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div> */}
				<div className="content-block">
					<div className="section-full content-inner-5 contact-form bg-white" style={{ backgroundImage: "url(" + bgimg2 + ")", backgroundSize: "100%" }}>
						<div className="container">
							<div className="tab-content product_list">
								<div id="web-design-1" className="tab-pane active">
									<p className="m-b0" style={{ fontSize: '20px' }}>Contact Us</p>
								</div>
							</div>
							<div className="row border border-1 m-5 bg-white" style={{ textAlign: 'center' }}>
								{(orderedContactDatas && orderedContactDatas.length > 0) ? orderedContactDatas.map((result, index) => {

									return <div className="col-xl-6 col-lg-6 col-md-6 " style={{ marginTop: '15px' }}>
										<div className="p-a30  contact-area  align-self-stretch radius-sm" >
											<h3 className="m-b5">{result.name}</h3>
											<p dangerouslySetInnerHTML={{ __html: result.content }}></p>

										</div>
									</div>
								})
									: ''
								}
								{/* <div className="col-lg-6 d-flex m-b30"></div> */}
								<div className="col-lg-12 d-flex m-b30">
									<MapData />
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}


export default Contact;