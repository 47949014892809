import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { websiteAddFavouriteCart, websiteFavouriteList,websiteViewFav } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "favourite";

const initialState = {
    loading: "initial",
    message: null,
    favloading: "initial",
    favmessage: null,
    favDatas : null,
    viewfavdatas : null

};


// export const favouriteData = createAsyncThunk(
//     `${namespace}/favourite`,
//     async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
//         try {
//             console.log("interdata");
//             //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
//             const data = await websiteFavouriteList(query, page, limit, sortby, order);
//             console.log("getCount--> ", data);
//             return data;
//         } catch (error) {
//             console.log("getCount error--->", error);
//             return rejectWithValue(error.response);
//         }
//     }
// );

export const favouriteData = createAsyncThunk(
    `${namespace}/getfavoritelist`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteFavouriteList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const viewFavData = createAsyncThunk(
    `${namespace}/viewfavdata`,
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteViewFav({ payload: { id: id } });
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const addfavouriteProductCart = createAsyncThunk(
    `${namespace}/addfavouritetocart`,
    async ({ productid=0 }, { rejectWithValue, dispatch }) => {
        try {

            const data = await websiteAddFavouriteCart({payload: {product_id: productid}});
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const favouriteSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [favouriteData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload, 'payload')
            state.favDatas = payload?.data;
        },
        [addfavouriteProductCart.fulfilled](state, { payload }) {
            state.favloading = API_STATUS.FULFILLED;
            console.log(payload, 'payload')
            state.favmessage = payload?.data.message;
        },
        [addfavouriteProductCart.rejected](state, action) {
            state.favloading = API_STATUS.REJECTED;
            state.favmessage = action?.payload?.data?.message;
        },
        [viewFavData.fulfilled](state, { payload }) {
            state.favloading = API_STATUS.FULFILLED;
            state.viewfavdatas = payload.data.data;
            console.log(payload,'payloadpayload');
            console.log(payload.data.data, 'payload')
        },
        [viewFavData.rejected](state, action) {
            state.favloading = API_STATUS.REJECTED;
            state.favmessage = action?.payload?.data?.message;
        },


    },
});

export const { clearData } = favouriteSlice.actions;

export const favouriteSelector = (state) => state.favourite;

export default favouriteSlice.reducer;
