import React, {  useEffect, useState} from 'react';
// import {Link} from 'react-router-dom';
import {Carousel} from 'react-bootstrap';
import slider1 from './../../images/main-slider/slide1.jpg';
import slider2 from './../../images/main-slider/mainbanner.png';
import { useDispatch, useSelector } from "react-redux";
import { bannerData, categorySelector } from '../../store/reducer/category';
import { assestURL } from '../../services/config';


function Slider(){

	const dispatch = useDispatch();
	const {bannerDatas} = useSelector(categorySelector);
	const [banners, setBanners] = useState([]);

	const BannerDispatch = () => {
        dispatch(
            bannerData({ })
        );
    };
	useEffect(() => {
		if(bannerDatas){
			console.log(bannerDatas, 'bannerDatas');

			if(bannerDatas.data){
				let slider_array = [];
				for (let index = 0; index < bannerDatas.data.length; index++) {
					
					let slideimage = bannerDatas.data[index].images;
					
					if(slideimage.length > 0){
						slideimage = slideimage.split(',');

						for (let index1 = 0; index1 < slideimage.length; index1++) {
							
							slider_array.push(slideimage[index1]);
						}
					}
					
					
				}
				setBanners(slider_array);

			}

		}
    }, bannerDatas)

	console.log(banners, 'banners slider_array');


	useEffect(() => {
		BannerDispatch()
    }, [])

	return(
		<div className="main-slider">
			<Carousel indicators={false}>
				{
					(banners.length > 0) ? banners.map((slider)=>{ 
					return <Carousel.Item>
					<div className="slide" style={{ backgroundImage: "url('"+ assestURL + slider +"')"}} >
						<div className="content"></div>	
					</div>	
				</Carousel.Item>
				}) :  <Carousel.Item>
				<div className="slide" style={{ backgroundImage: "url("+ slider2 +")"}} >
					<div className="content"></div>	
				</div>	
			</Carousel.Item>
			}
				
			</Carousel>
		</div>
	)	
}

export default Slider;